/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demoLoginAPI, loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";
import { JSEncrypt } from "jsencrypt";
import toast, { Toaster } from "react-hot-toast";
import { generateHash } from "../service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import icon from "../assets/icon.png";

export default function PublicOutletM(props) {
  const imgRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleDragStart = (clientX, clientY) => {
    const img = imgRef.current;
    setIsDragging(true);
    setOffset({
      x: clientX - img.offsetLeft,
      y: clientY - img.offsetTop,
    });
  };
  const handleDragMove = (clientX, clientY) => {
    if (isDragging) {
      const img = imgRef.current;
      img.style.left = `${clientX - offset.x}px`;
      img.style.top = `${clientY - offset.y}px`;
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = (e) => handleDragStart(e.clientX, e.clientY);
  const handleMouseMove = (e) => handleDragMove(e.clientX, e.clientY);
  const handleMouseLeave = handleDragEnd;
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    handleDragStart(touch.clientX, touch.clientY);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    handleDragMove(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = handleDragEnd;

  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.account.userData);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const preventBackNavigation = () => {
      if (location.pathname === "/login") {
        navigate("/login", { replace: true });
      }
    };

    window.addEventListener("popstate", preventBackNavigation);
    return () => {
      window.removeEventListener("popstate", preventBackNavigation);
    };
  }, [location.pathname, navigate]);

  const login = async (isDemo = false) => {
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);

    const loginMethod = isDemo ? demoLoginAPI : loginAPI;

    var { data, code, message, response, error } = await loginMethod(
      userName,
      password,
      isDemo
    );
    if (code === 200) {
      localStorage.setItem("token", response.token);
      dispatch({
        type: "accountData/setUserData",
        payload: { ...response, isDemo },
      });

      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });

      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
      if (response?.mfaEnabled) {
        navigate("/otp", {
          state: {
            userName,
          },
        });
      } else {
      }
    } else if (code == 403) {
      toast.error("Invalid User Name or Password");
    } else if (code == 401) {
      toast.error(response);
    } else {
      toast.error("Account is disabled or Something went wrong");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login(false);
    }
  };

  return (
    <>
      <div className="">
        <a
          href="https://wa.me/7737516309"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="iconrob"
            style={{
              position: "fixed",
              right: "0px",
              bottom: "0px",
              width: "40px",
              height: "40px",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img
              ref={imgRef}
              src={icon}
              alt="Draggable Icon"
              style={{
                position: "absolute",
                left: -310,
                top: -150,
                width: 70,
                height: "auto",
                cursor: "move",
              }}
            />
          </div>
        </a>
      </div>
      <div className="wrapper">
        <div className="login-page">
          <div className="login-box">
            <div className="logo-login">
              <a href="/">
                <img src="logo.png" />
              </a>
            </div>
            <div className="login-form mt-4">
              <h4 className="text-center login-title">
                Login <i className="fas fa-hand-point-down" />
              </h4>
              <div>
                <div className="mb-4 input-group position-relative">
                  <input
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <span className="input-group-text">
                    <i className="fas fa-user" />
                  </span>
                </div>
                <div className="mb-4 input-group position-relative">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <span className="input-group-text">
                    <i className="fas fa-key" />
                  </span>
                </div>
                <div className="d-grid">
                  <button
                    onClick={(e) => login(false)}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Login
                    <i className="fas fa-sign-in-alt float-end mt-1" />
                  </button>
                  <button
                    onClick={(e) => login(true)}
                    type="button"
                    className="btn btn-primary btn-block mt-2"
                  >
                    Login with demo ID
                    <i className="fas fa-sign-in-alt float-end mt-1" />
                  </button>
                </div>
                <small className="recaptchaTerms mt-1">
                  This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  and
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </small>
                <p className="mt-1" />
                <section className="footer footer-login">
                  <div className="footer-top">
                    <div className="footer-links">
                      <nav className="navbar navbar-expand-sm">
                        <ul className="navbar-nav">
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/terms-and-conditions"
                              target="_blank"
                            >
                              {" "}
                              Terms and Conditions{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/responsible-gaming"
                              target="_blank"
                            >
                              {" "}
                              Responsible Gaming{" "}
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="support-detail">
                      <h2>24X7 Support</h2>
                      <p />
                    </div>
                    <div className="social-icons-box" />
                  </div>
                </section>
              </div>
              <div>
                <div>
                  <div
                    className="grecaptcha-badge"
                    data-style="bottomright"
                    style={{
                      width: 256,
                      height: 60,
                      display: "block",
                      transition: "right 0.3s",
                      position: "fixed",
                      bottom: 14,
                      right: "-186px",
                      boxShadow: "gray 0px 0px 5px",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <div className="grecaptcha-logo">
                      <iframe
                        title="reCAPTCHA"
                        width={256}
                        height={60}
                        role="presentation"
                        name="a-dqx70v8zb460"
                        frameBorder={0}
                        scrolling="no"
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                        src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LfX5uUhAAAAAEWCO0-VmoLKyCBqr16p2oe_PFkV&co=aHR0cHM6Ly9ncmFuZGV4Y2guY29tOjQ0Mw..&hl=en&type=image&v=-ZG7BC9TxCVEbzIO2m429usb&theme=light&size=invisible&badge=bottomright&cb=ycszheyrpc6v"
                      />
                    </div>
                    <div className="grecaptcha-error" />
                    <textarea
                      id="g-recaptcha-response"
                      name="g-recaptcha-response"
                      className="g-recaptcha-response"
                      style={{
                        width: 250,
                        height: 40,
                        border: "1px solid rgb(193, 193, 193)",
                        margin: "10px 25px",
                        padding: 0,
                        resize: "none",
                        display: "none",
                      }}
                      defaultValue={""}
                    />
                  </div>
                  <iframe style={{ display: "none" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
